.pageWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--theme-spacing-stack-sm);
  padding: var(--theme-spacing-stack-xxs) 0px var(--theme-spacing-stack-sm) 0px;
}

.center {
  align-items: center;
}

.mdnCheckbox {
  width: fit-content;
  label{
    font-size: 14px;
  }
}

.btnConsultar{
  @media screen and (max-width: 527px) {
    // For Mobile
    margin-top: 1.25rem;
  }
}
