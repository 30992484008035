.pageWrapper {
  min-height: 100vh;

  display: grid;

  grid-template-areas:
    "header"
    "main"
    "footer";

  grid-template-rows: min-content 1fr min-content;

  header {
    position: inherit;
    z-index: 2;
    top: 0;
    grid-area: header;
  }

  main {
    grid-area: main;

    .banner {
      background-repeat: no-repeat;
      background-size: cover;
      height: 205px;

      display: flex;
      align-items: center;

      @media screen and (min-width: 480px) and (max-width: 835px) {
        // For Tablet
        height: 280px;
      }

      @media screen and (min-width: 835px) {
        // For Desktop
        height: 300px;

        @media screen and (min-width: 962px) {
          justify-content: center;
        }
      }

      div {
        margin-right: var(--theme-spacing-inline-md);
        width: 100%;
        @media screen and (min-width: 962px) {
          // For Desktop
          max-width: 72rem;
        }
        @media screen and (max-width: 480px) {
          // For Mobile
          margin-right: var(--theme-spacing-inline-xxs);
        }

      .bannerHeading {
        width: 350px;
        margin-bottom: 0px;
        margin-left: auto;
        font-weight: var(--theme-font-weight--Text);
        color: var(--theme-color-neutral-lightest);

        strong {
          font-weight: var(--theme-font-weight-bold);
        }

          @media screen and (min-width: 481px) and (max-width: 961px) {
            // For Tablets
            width: 250px;
          }

          @media screen and (max-width: 480px) {
            // For Mobile
            width: 150px;
            font-size: var(--font-size-xxs);
            line-height: 16px;
          }
        }
      }
    }

    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-top: 1rem;
      padding-right: 1rem;
      padding-bottom: 2rem;
      padding-left: 1rem;

      @media screen and (min-width: 962px) {
        // For Desktop
        align-items: center;
      }

      @media screen and (min-width: 481px) and (max-width: 960px) {
        // For Tablets
        padding-top: 1rem;
        padding-right: 4.5rem;
        padding-bottom: 2rem;
        padding-left: 4.5rem;
      }

      .container {
        .bodyText {
          line-height: 28px;
          font-weight: bold;
          margin-top: 1rem;
          padding-bottom: 1rem;
          line-height: 1.5rem;
        }

        .bodyText:nth-child(2) {
          .textBold{
            font-weight: var(--theme-font-weight-bold);
          }
        }

        .ctaArea {
          margin-top: -1rem;
        }

        @media screen and (min-width: 962px) {
          // For Desktop
          width: 100%;
          max-width: 72rem;

          .ctaArea {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 1rem;
          }
        }

        .conhecaDiferencaBtn {
          background: none;
          color: var(--theme-color-brand-primary-dark-clickable);
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          text-decoration: underline;
          font-weight: 500;
        }
      }
    }
  }

  footer {
    grid-area: footer;
  }

  .backgroundDesktop {
    background: url("../assets/porteira/banner-desktop.webp");
  }

  .backgroundMobile {
    background: url("../assets/porteira/banner-mobile.webp");
  }

  .backgroundTablet {
    background: url("../assets/porteira/banner-tablet.webp");
  }
}
