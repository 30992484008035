.locationCard {
  display: flex;
  flex-direction: row;
  gap: var(--theme-spacing-inline-xxs);
  padding: 1rem;

  border: 1px solid var(--theme-borderColor-Menu);
  box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.17);
  border-radius: 8px;

  margin-top: var(--theme-spacing-stack-xs);

  h1 {
    margin-bottom: 0px;
  }

  span {
    color: var(--theme-text-color-Label--hat);
  }
}

.btnsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--theme-spacing-stack-xs);
  margin-top: var(--theme-spacing-stack-xxl);
}
