.card {
  width: 100%;
  height: 82px;
  padding: var(--theme-spacing-inset-sm);
  border-radius: var(--theme-border-radius-lg); // 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  box-shadow:
    var(--theme-shadow-offset-x-none)
    var(--theme-shadow-offset-y-level-1)
    var(--theme-shadow-blur-radius-level-1)
    rgba(0, 0, 0, 0.08);

  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;

  div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--theme-spacing-inline-xs);
    width: 100%;
  }

  .bodyText {
    color: var(--theme-color-neutral-darkest-clickable);
    font-weight: var(--theme-font-weight-medium);
    line-height: var(--theme-font-line-height-xxs);
    text-align: left;
  }

  .captionText {
    color: var(--theme-color-neutral-darkest-clickable);
    font-weight: var(--theme-font-weight-medium);
    line-height: var(--theme-font-line-height-xxs);
    text-align: left;
  }

  span {
    color: var(--theme-color-brand-primary-dark-clickable);
  }

  @media screen and (min-width: 991px) {
    // For Desktop
    max-width: 455px;
  }
}

.btnClienteClaro {
  background-color: var(--color-neutral-lightest-bg);
  border: var(--theme-border-width-thin) solid var(--theme-color-neutral-medium-clickable);
  margin-top: 1.5rem;
}
