footer {

.disclaimer {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
  }

  .link {
    font-size: 14px;
  }

  .logoImg {

    // Para Mobile e Tablet
    @media only screen and (min-width: 1000px) {
      margin: 0px !important;

    }
  }
}

.socialContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  padding: 5px;
  min-width: 170px;

  @media only screen and (min-width: 1000px) {
    width: 100px;
  }
}

.disclaimerLink{
  cursor: pointer;
}

.footerContent{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (min-width:991px) {
    margin-bottom: 0;
  }
}
